import imageOne from "./images/florencio-1.jpg";
import imageTwo from "./images/florencio-2.jpg";
import imageThree from "./images/florencio-3.jpg";
import imageFour from "./images/florencio-4.jpg";
import imageFive from "./images/florencio-5.jpg";
import imageSix from "./images/florencio-6.jpg";
import imageSeven from "./images/florencio-7.jpg";
import imageEight from "./images/florencio-8.jpg";
import imageNine from "./images/florencio-9.jpg";

// 900px x 900px
const data = [
  {
    id: 1,
    name: "Island",
    image: imageOne
  },
  {
    id: 2,
    name: "Forest",
    image: imageTwo
  },
  {
    id: 3,
    name: "Whale",
    image: imageThree
  },
  {
    id: 4,
    name: "Mountain",
    image: imageFour
  },
  {
    id: 5,
    name: "Boat",
    image: imageFive
  },
  {
    id: 6,
    name: "Flowers",
    image: imageSix
  },
  {
    id: 7,
    name: "Fire",
    image: imageSeven
  },
  {
    id: 8,
    name: "Garden",
    image: imageEight
  },
  {
    id: 9,
    name: "Bridge",
    image: imageNine
  }
];

export default data;
