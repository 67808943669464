import React, { useEffect } from "react";
import "./App.css";
import Slider from "./components/Slider";
import Navigation from "react-sticky-nav";
import "animate.css";

function App() {
  const [scrolled, setScrolled] = React.useState(false);
  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 500) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });

  return (
    <div className="App">
      <Navigation className={scrolled ? "sticky-nav show-nav" : "sticky-nav"}>
        <h1>Florencio</h1>
      </Navigation>
      <header className="App-header animate__animated animate__zoomInDown animate__slow">
        <h1>Florencio</h1>
      </header>
      <Slider />
      <div className="contact">
        <p>
          <i>
            Florencio is a family run home bakery. We create authentic
            Argentinian desserts that will delight your tastebuds. We also take
            requests and add an original twist to all of our desserts. For all
            enquires please contact us by mobile, or checkout instagram for some
            order inspiration.{" "}
          </i>
        </p>
        <p>
          <a href="tel:+34622743623">
            <strong>📱+34 622743623</strong>
          </a>
        </p>
        <p>
          <a href="https://www.instagram.com/florencio_bcn/">
            <strong>@florencio_bcn</strong>
          </a>
        </p>
      </div>
    </div>
  );
}

export default App;
